@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
body {
  font-family: Poppins, sans-serif !important;
}

@media only screen and (width <= 600px) {
  .is-size-5 {
    margin-inline: -3rem;
  }
}

.shop {
  width: max-content;
  min-width: 255px;
  height: 300px;
}

.teamphoto {
  border-radius: 20px;
}

@media only screen and (width <= 600px) {
  .teamphoto {
    border-radius: 20px;
    width: 120%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.center {
  justify-content: center;
  margin: auto;
  display: flex;
}

.center_horizontal {
  justify-content: center;
  display: flex;
}

.parallax {
  opacity: .6;
  background-image: url("robot.8adc6fcc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}
/*# sourceMappingURL=index.c0f48c31.css.map */
